import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import notFoundPuppet from '../assets/404_Puppet.svg';

import './404.scss';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Arduino: Page Not found" />
    <div className="not-found">
      <img src={notFoundPuppet} />
      <p>
        To report a broken link into the Arduino website, please drop a line to{' '}
        <a href="mailto:webmaster@arduino.cc">webmaster@arduino.cc</a>
      </p>
      <p>
        Go back to <a href="https://id.arduino.cc/">your profile</a> or{' '}
        <a href="https://www.arduino.cc/">the homepage</a>
      </p>
    </div>
  </Layout>
);

export default NotFoundPage;
